<template>
  <div class="wrap">
    <p class="dateMsg">生效时间：2021年12月3日</p>
    <div class="title">药百万用户协议</div>
    <div class="wrap-in">
      <!-- <p class="IndentBox">
        药百万是由山西复盛公药业集团有限公司（以下简称“本公司”）为药店、诊所提供订货的APP。为保证您的权益，便于更好地使用药百万及相应的配套服务，请您务必在注册前认真阅读本协议，若您阅读并接受本协议，使用药百万提供的产品和服务，即视为您受本协议的约束，若您不同意本协议，请勿使用本网站任何产品和服务。
      </p> -->
      <p class="text">一、服务条款的确认及接受</p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.本协议由山西复盛公健康药业有限公司（以下称“复盛公”）与申请注册后使用本网站（或称”本软件“）服务的用户（以下称”您“或”用户“）共同订立，复盛公同意按照本协议的规定及其不时发布的操作规则提供药百万客户端软件或网站（以下称“药百万”）各项电子服务，药百万各项电子服务的所有权和运作权归属于“复盛公”所有，复盛公提供的服务将完全按照其发布的服务条款和操作规则严格执行。您点击勾选本协议，即视为您完全理解并接受本协议全部内容，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。您确认所有服务条款并完成注册程序时，本协议在您与复盛公间成立并发生法律效力，同时您成为复盛公正式用户，如果您不同意本协议的任意内容，或者无法准确理解本公司对条款的解释，请不要进行后续操作，包括但不限于不要接受本协议，不使用本服务。
      <br />
      2、根据国家法律法规变化及药百万运营需要，复盛公有权对本协议条款及相关规则不时地进行修改，修改后的内容将以公告形式公布在药百万后生效，并取代此前相关内容，您应不时关注药百万公告、提示信息及协议、规则等相关内容的变动。您知悉并确认，如您不同意更新后的内容，应立即停止使用药百万服务；如您继续使用药百万，即视为知悉变动内容并同意接受。
      <br />
      3、为提高用户的使用感受和满意度，用户同意复盛公将基于用户的操作行为对用户数据进行
      调查研究和分析，从而进一步优化药百万的服务。<br />

      <p class="text">二、服务需知</p>
      1、基于药百万所提供的网络服务的重要性，您确认并同意：<br />
      <div class="secBox">
        （1）提供的注册资料真实、准确、完整、合法、有效，注册资料如有变动的，应及时更新。<br />
        （2）如果您提供的注册资料不合法、不真实、不准确、不详尽或无效的，您需承担因此引起的相应责任及后果，并且复盛公有权终止您使用药百万各项服务的权利。
      </div>
      2、复盛公药百万的具体服务由复盛公或其关联公司、合作伙伴根据实际情况提供，例如在线学习、充值提现、采购信息服务等；复盛公有权根据经营策略、经营管理需要或市场变化等因素，变更、中断或终止部分或全部服务。
      <br />
      3、复盛公药百万为用户提供商品信息展示与交易服务，用户在复盛公药百万使用采购服务购买并付款。
      <br />

      <p class="text">三、订单</p>
      1、商品的订购、支付货款、验收与确认
      <div class="secBox">
        （1）用户自愿接受本协议和复盛公药百万网站或软件相关规则，并申请账户，经复盛公审核成功后，方可使用复盛公药百万各项服务。
        <br />
        （2）用户订购商品的数量与价格以实际生成的订单为准，生成订单之前用户加入购物车的
        商品的数量与价格可能随时变化，因此用户应尽快完成订单的支付。
        <br />
        （3）用户生成订单并完成付款后，供应商将订购的商品发出，用户在货物发出后不得无理
        由进行退换货或拒收货物，退换货或拒收货物需经供应商同意，否则产生的纠纷及损失由用户独立承担。
        <br />
        （4）用户应当在收到商品的72小时内在药百万确认收货，确认收货前，用户应当认真检查商品外包装是否破损、商品遗失，并根据物流运单认真审核商品件数，当出现疑问或发现问题时，必须当场与送货人员进行核实及确定问题原因，并在当日向业务员/客服/区域经理反馈，明确处理意见。
        <br />
        （5）用户不得借故不正当或虚假理由，要求退货或取消订单。
        <br />
      </div>
      2、货款的监管与结算：
      <div class="secBox">
        用户订单提交后应按照药百万网站提供的付款方式、期限按时支付货款，请关注平台提示。
        <br />
      </div>

      <p class="text">四、配送</p>
      1、您在药百万购买的商品将由复盛公发货，按照您的医疗器械经营许可证或医疗器械备案上的、经国家相关监督管理部门核准的地址为送货地址进行配送。
      您应严格按照《医疗器械监督管理条例》等法律要求对所购买的商品确认收货，因您未按要求确认收货造成的损失和责任由您自行承担。
      <br />
      2、因如下情况造成订单延迟或无法配送等，用户应自行承担配送迟延的不利后果或责任：
      <br />
      <div class="secBox">
        （1）用户提供错误信息和不详细的地址；<br />
        （2）货物送达无人签收，由此造成的重复配送所产生的费用及相关的后果。
        <br />
        （3）不可抗力，例如：自然灾害、交通戒严、突发战争等。
        <br />
      </div>
      <p class="text">五、药百万用户信息保护及授权</p>
      1、您知悉并同意，依据相关法律和行政法规的规定，复盛公将存储您在使用时的必要信息，包括但不限于您的经营主体信息、配送地址、联系方式、通讯录、相册、日历、
      定位信息等。除法律法规规定的情形外，未经您的许可复盛公不会向第三方公开、透露您的商业秘密信息。复盛公对相关信息采取专业加密存储与传输方式，利用合理措施保障用户信息的安全。
      <br />
      2、您知悉并确认，您在注册帐号或使用复盛公药百万的过程中，需要提供真实的资质信息，复盛公药百万将根据国家法律法规相关要求，进行真实信息认证。若您提供的信息不真实、不完整、不合法或无效，则无法使用复盛公药百万或在使用过程中受到限制，同时，由此产生的不利后果，由您自行承担。
      <br />
      3、您充分理解并同意：
      <div class="secBox">
        （1）接收复盛公或其关联公司、合作伙伴通过邮件、短信、电话等形式，向在复盛公药百万注册、购物的用户、收货人发送的订单信息、促销活动信息等内容；<br />
        （2）为配合行政监管机关、司法机关执行工作，在法律规定范围内复盛公有权向上述行政、司法机关提供您在使用复盛公药百万时所储存的相关信息，包括但不限于您的注册信息等，或使用相关信息进行证据保全，包括但不限于公证、见证等；
        <br />
        （3）复盛公药百万依法保障您在安装或使用过程中的知情权和选择权，在您使用复盛公药百万服务过程中，涉及您设备自带功能的服务会提前征得您同意，您一经确认，复盛公药百万有权开启包括但不限于收集地理位置、读取通讯录、使用摄像头等提供服务必要的辅助功能。
        <br />
        （4）复盛公有权根据实际情况，在法律规定基础上自行决定单个用户在复盛公药百万及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。
      </div>

      <p class="text">六、用户使用规则</p>
      1、用户使用经营主体名称为账户注册复盛公药百万，该帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。若因为用户自身原因，而导致帐号、密码遭他人非法使用时，用户应自行承担相关责任，并赔偿因此给复盛公及其关联公司或其它相关主体造成的全部损失。
      <br />
      2、用户进行实名认证和获取终端码时，应提供真实有效的证件，如证件被查明与事实不符
      或失效，复盛公有权冻结用户通过复盛公药百万获得的收益，暂定或删除帐号。
      <br />
      3、用户在使用复盛公药百万过程中，必须遵循以下原则：
      <div class="secBox">
        （1）遵守中国有关的法律和法规；
        <br />
        （2）不得为任何非法目的而使用复盛公药百万；
        <br />
        （3）如发现任何非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告复盛公。
        <br />
      </div>

      <!-- （四）共享、转让、公开披露需要授权的例外
      <div class="secBox">
        根据法律法规及国家标准，以下情形中我们可能会共享、转让、公开披露您的个人信息无需征得您的同意：
        <br />1、与国家安全、国防安全直接相关的；
        <br />2、与公共安全、公共卫生、重大公共利益直接相关的；
        <br />3、与犯罪侦查、起诉、审判和判决执行等直接相关的；
        <br />4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您的同意的；
        <br />5、您自行向社会公众公开的个人信息；
        <br />6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
        <br />请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
      </div> -->
      <p class="text">七、复盛公药百万使用规范</p>
      1、关于复盛公药百万的获取与更新：
      <div class="secBox">
        （1）您可以直接从复盛公药百万的网站上获取复盛公药百万，也可以从得到复盛公健康授权的第三方获取，如果您从未经复盛公授权的第三方获取复盛公药百万或与复盛公药百万名称相同的安装程序，复盛公无法保证该软件能够正常使用，并对因此给您造成的损失不予负责；
        <br />
        （2）为了改善用户体验、完善服务内容，复盛公药百万将不断努力开发新的服务，并为您不时提供软件更新，复盛公药百万新版本发布后，旧版本的软件可能无法使用，复盛公药百万不保证旧版复盛公药百万继续可用及相应的客户服务，请您随时核对并下载最新版本。
        <br />
      </div>
      2、除非法律允许或复盛公书面许可，您使用复盛公药百万过程中不得从事下列行为：
      <div class="secBox">
        （1）删除复盛公药百万及其副本上关于著作权的信息；
        <br />
        （2）对复盛公药百万进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现，复盛公药百万的源代码；
        <br />
        （3）对复盛公药百万或者复盛公药百万运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及复盛公药百万运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经复盛公药百万授权的第三方工具/服务接入复盛公药百万和相关系统；
        <br />
        （4）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
        <br />
        （5）通过非复盛公开发、授权的第三方软件、插件、外挂、系统，登录或使用复盛公药百万软件及服务，或制作、发布、传播上述工具；
        <br />
        （6）自行或者授权他人、第三方软件对复盛公药百万及其组件、模块、数据进行干扰。
        <br />
      </div>
      <p class="text">八、违约责任</p>
      1、如果复盛公发现或收到他人举报投诉您违反本协议约定的，复盛公有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号进行包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处理。
      <br />
      2、复盛公有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
      <br />
      3、您理解并同意，因您违反法律、法规、本协议或相关服务条款的规定，导致或产生行政处罚及任何索赔、要求或损失，您应当独立承担责任；复盛公或其关联公司因此遭受损失的，您也应当一并赔偿。
      <br />
      4、除非另有明确的书面说明,复盛公不对复盛公药百万的运营及其包含在复盛公药百万上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
      <br />

      <p class="text">九、法律管辖适用及其他</p>

      1、本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的人民法院提起诉讼。
      <br />
      2、如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
      <br />
      3、本协议未明示授权的其他权利仍由复盛公保留，您在行使这些权利时须另外取得复盛公的书面许可。复盛公如果未行使前述任何权利，并不构成对该权利的放弃。
      <br />
      <p class="text">十、账户注销流程：</p>
      《复盛公药百万账户注销须知》
      <br />
      您在申请注销流程中点击同意前，应当认真阅读《复盛公药百万账户注销须知》（以下称“《注销须知》”）。
      <br />
      请您务必审慎阅读、充分理解协议中相关条款内容，其中包括：
      <div class="secBox">
        （1）与您约定免除或限制责任的条款；
        <br />
        （2）其他以粗体下划线标识的重要条款。
      </div>

      <p class="text">亲爱的复盛公药百万用户：</p>

      <p class="IndentBox">
      我们在此善意地提醒您，您注销账户的行为会给您的售后维权带来诸多不便，且注销账户后，您的信息我们只会在复盛公药百万的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，相关交易记录将在复盛公药百万后台被保存5年，甚至更长的时间。

      <br />
      1、如果您仍执意注销账户，您的账户需同时满足以下条件：

      <div class="secBox">
        （1）在最近一个月内，账户没有进行更改密码、更改绑定信息等敏感操作；
        <br />
        （2）账户内无未完成的订单和服务；
        <br />
        （3）账户无任何纠纷，包括投诉举报或被投诉举报；
        <br />
        （4）账户对应的支付账户已注销，且已妥善处理了支付账户下的所有问题；
        <br />
        （5）账户已经解除与其他网站、其他APP的授权登录或绑定关系。
      </div>
      2、复盛公药百万账户一旦被注销将不可恢复，请您在操作之前自行备份复盛公药百万账户相关的所有信息和数据。请您保存好订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受售后服务。
      <br />
      3、在复盛公药百万账户注销期间，如果您的复盛公药百万账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，复盛公有权自行终止本账户的注销而无需另行得到您的同意。
      <br />
      4、注销复盛公药百万账户，您将无法再使用本账户，也将无法找回您账户中及与账户相关的任何内容或信息，包括但不限于：
      <br />

      <div class="secBox">
        （1）您将无法登录、使用本复盛公药百万账户；
        <br />
        （2）本复盛公药百万账户的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息等）都将无法找回；请您在提交注销申请前，务必先了解您须解绑的其他相关账户信息，具体可与我们的客服联系。
      </div>
      5
      .注销本复盛公药百万账户并不代表本账户注销前的账户行为和相关责任得到豁免或减轻。
    </div>
  </div>
</template>

<style lang="less" scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;
  padding: 10px 0 30px 0;
  .title {
    height: 40px;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }
  .dateMsg{
     font-size:14px;
      line-height: 24px;
     font-weight: 600;
  }
  .text {
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0 10px 0;
  }
  .wrap-in {
    line-height: 24px;
    font-size: 14px;
    .IndentBox {
      text-indent: 28px;
    }
  }
  .wrap-note {
    color: red;
    text-decoration: underline;
    // background: #fff;
    // font-style: italic;
    padding: 0;
    span {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .secBox {
    padding: 0 20px;
  }
}
</style>

<script>
import { mapMutations } from "vuex";
import { getToken } from "utils/session.js";
export default {
  name: "Agreement",
  //路由进入时  显示未登陆状态头部
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      vm.changeFlag(true);
    });
  },
  //路由离开时 隐藏未登陆状态头部
  beforeRouteLeave(to, from, next) {
    if (getToken() != null) {
      this.changeFlag(false);
    }
    next();
  },
  methods: {
    ...mapMutations({
      changeFlag: "changeFlag",
    }),
  },
};
</script>
